import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const ResourceCardIH = ({ cardData }) => {
  return (
    <div tw="flex flex-wrap flex-col md:flex-row ">
      {cardData &&
        cardData.map((card, i) => {
          return (
            <a
              key={i}
              href={card.pdf}
              target="_blank"
              rel="noopener noreferrer"
              className="group"
              css={[
                tw`flex w-full rounded-[50px] mb-[28px] border border-[#9A7DD1] md:(w-[calc(50% - 15px)] min-h-[243px] rounded-[60px]) md:odd:(mr-[15px]) md:even:(ml-[15px])`,
                tw`gradient34`,
                tw`hover:(gradient34Hover text-white )`,
                tw`focus-visible:( outline-none relative
                  before:(block absolute rounded-[65px] w-[calc(100% + 30px)] h-[calc(100% + 30px)] top-[-15px] left-[-15px] border border-dashed border-[#949494])
                )`,
              ]}
            >
              <div tw="flex flex-col justify-between leading-[23.4px] mx-6 md:mx-[45px]">
                <div>
                  <h3 tw="uppercase font-semibold mt-8 mb-3 md:(mt-10 leading-[20.8px])">
                    {card.title}
                  </h3>
                  <p tw="font-normal mb-6 lg:(mb-[15px])">{card.description}</p>
                </div>

                <div
                  css={[
                    tw`mb-8 md:mb-[30px] flex items-center justify-between`,
                  ]}
                >
                  <p tw="font-normal leading-[20.8px]">
                    PDF<span tw="mx-4">| </span>
                    {card.size}
                  </p>
                  <div css={[tw`min-w-[23px] lg:min-w-[31px]`]}>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 23 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.5 10L11.7991 21L1.5 10"
                        strokeWidth="3"
                        css={[tw`stroke-[#7147BD] group-hover:stroke-[white]`]}
                      ></path>
                      <path
                        d="M11.5 21V0"
                        strokeWidth="3"
                        css={[tw`stroke-[#7147BD] group-hover:stroke-[white]`]}
                      ></path>
                      <path
                        d="M1 29L22 29"
                        strokeWidth="3"
                        css={[tw`stroke-[#7147BD] group-hover:stroke-[white]`]}
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          )
        })}
    </div>
  )
}

export default ResourceCardIH

ResourceCardIH.propTypes = {
  narcolepsy: PropTypes.bool,
  ih: PropTypes.bool,
  cardData: PropTypes.array.isRequired,
}

ResourceCardIH.defaulProps = {
  narcolepsy: false,
  ih: false,
  cardData: [],
}
