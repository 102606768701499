import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import playIcon from "@images/icons/icon-play-video-ih.svg"
import arrowRed from "@images/icons/icon-arrow-red-right.svg"
import { useWindowSize } from "@components/utils"
import InlineVideo from "./inlineVideo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AppContext } from "./context"
import { isBrowser, isEDMode } from "./utils"

const VideoSelector = ({ videos, title, description, selectedVideo }) => {
  const context = useContext(AppContext)

  const [currentVideo, setCurrentVideo] = useState(videos[selectedVideo])
  const [expanded, setExpanded] = useState(false)

  const { width } = useWindowSize()
  // Reset expanded for desktop
  useEffect(() => {
    if (width >= 768) {
      setExpanded(true)
    }
  }, [width])
  useEffect(() => {
    // Screenshots
    if (isBrowser) {
      const isEDMode = window.location.href.indexOf("ed=1") > -1
      if (isEDMode) {
        setExpanded(true)
      }
    }
  }, [])
  useEffect(() => {
    setCurrentVideo(videos[selectedVideo])
  }, [selectedVideo])
  const onVideoSelectorClick = (e, video) => {
    setCurrentVideo(video)
    !isEDMode && context.setVideoPlaying(video.id)

    const el_inlinevideo = e.target.closest(".video-selector")
    if (el_inlinevideo) {
      el_inlinevideo.scrollIntoView()
    }
  }

  return (
    <div className="video-selector" id={"video-playing" + currentVideo.id}>
      <p
        css={[tw`text-[16px] pb-[24px] font-medium md:(text-[18px] pb-[12px])`]}
      >
        {title}
      </p>

      <InlineVideo
        wistiaId={currentVideo.id}
        copy={currentVideo.title}
        eyebrowText={description}
        thumbnail={currentVideo.thumb}
        alt={currentVideo.alt}
        isGallery={true}
        isIH={true}
      />

      <button
        onClick={() => setExpanded(!expanded)}
        css={[
          tw`block w-[100vw] bg-white px-[17.5px] py-[16px] mt-3 mx-[-17.5px] md:hidden`,
        ]}
      >
        <div
          css={[
            tw`text-[14px] font-semibold flex justify-between items-center`,
          ]}
        >
          <div>More Videos</div>
          <div>
            <img
              css={[tw`rotate-90`, expanded && tw`rotate-[270deg]`]}
              src={arrowRed}
            />
          </div>
        </div>
      </button>

      <div
        className="video-selector-menu"
        css={[
          tw`flex flex-wrap items-start flex-col md:(flex-row place-content-stretch pt-[16px] gap-[10px] mx-[-10px]) xl:max-w-[1095px]`,
          !expanded && tw`hidden`,
        ]}
      >
        {videos.map((video, i) => {
          const isCurrentVideo = video.id === currentVideo.id
          return (
            <button
              key={i}
              onClick={e => onVideoSelectorClick(e, video)}
              css={[
                tw`relative text-left py-3 md:(py-2 px-2 border-2 border-[transparent] rounded-lg)`,
                tw`w-full border-b border-b-[#DBDBDB] md:(text-[#9A7DD1] w-[calc(50% - 6px)] hover:(border-[#9A7DD1])) lg:(w-[calc(25% - 12px)] min-w-[350px]) xl:min-w-[265.5px]`,
                isCurrentVideo &&
                  tw`text-[#7147BD] font-bold md:(bg-[#AD95DC] text-white hover:(border-[#AD95DC]))`,
                tw`focus-visible:( outline-none relative
                  before:(block absolute rounded-lg w-[calc(100% + 14px)] h-[calc(100% + 14px)] top-[-7px] left-[-7px] border border-dashed border-[#949494])
                )`,
              ]}
            >
              <div
                className="video-selector-menu-thumb"
                css={[tw`h-[175px] bg-gray-400 relative hidden md:block`]}
              >
                <GatsbyImage
                  className="video-thumb"
                  image={getImage(video.thumb)}
                  alt={video.alt}
                />
                <div
                  tw="absolute left-[calc(50% - 24px)] top-[calc(50% - 24px)]"
                  aria-label="Play"
                >
                  <img
                    src={playIcon}
                    tw="w-[48px] h-[48px]"
                    alt=""
                    data-id={"videoId"}
                  />
                </div>
              </div>

              <div
                css={[
                  tw`text-[14px] uppercase pt-2 md:(text-[16px] underline font-medium min-h-[49.5px])`,
                ]}
              >
                {video.title}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default VideoSelector
