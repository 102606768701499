import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { withArtDirection, getImage } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import HeroIH from "@components/heroIH"
import HeroLinks from "@components/heroLinks"
import Section from "@components/section"
import Container from "@components/container"
import References from "@components/references"
import ResourceCardIH from "@components/resourceCardIH"
import AnchorIH from "@components/anchorIH"
import Crosslinks from "@components/crosslinks"
import { Heading2 } from "@styles/ui"

// Assets

import VideoSelector from "../../components/videoSelector"

const ResourcesIH = ({ location, data }) => {
  const [leaderSelectedVideo, setLeaderSelectedVideo] = useState(0)
  const [patientSelectedVideo, setPatientSelectedVideo] = useState(0)

  useEffect(() => {
    const hashValues = ["#patient-videos", "#KOL-videos"]
    const searchParams = new URLSearchParams(location.search)

    if (hashValues.indexOf(location.hash) > -1) {
      let delay = 1000
      window.scrollTo({
        top: 0,
        behavior: "instant",
      })
      if (location.hash === "#patient-videos" && location.search.length > 0) {
        setPatientSelectedVideo(searchParams?.get("selectVideo") || 0)
      } else if (
        location.hash === "#KOL-videos" &&
        location.search.length > 0
      ) {
        setLeaderSelectedVideo(searchParams?.get("selectVideo") || 0)
      }
      setTimeout(() => {
        document.querySelector(`${location.hash}`).scrollIntoView({
          behavior: "smooth",
        })
      }, delay)
    }
  }, [])
  const resourceCardData = [
    {
      title: "Prescriber Checklist",
      description:
        "A quick guide to prescribing XYWAV. A print version is also available from your Jazz Representative.",
      size: "348 kb",
      pdf: "/pdf/XYWAV-HCP-Prescriber-Checklist-Digital-PDF-1.pdf",
    },
    {
      title: "Idiopathic Hypersomnia Patient Identification Leave Behind",
      description:
        "A helpful diagnosis and patient identification resource. A print version is also available from your Jazz Representative.",
      size: "613 kb",
      pdf: "/pdf/XYWAV-Patient-ID-Digital-PDF.pdf",
    },
    {
      title: "Efficacy Leave Behind",
      description:
        "Key efficacy and safety information you need to know about XYWAV. A print version is also available from your Jazz Representative.",
      size: "1.5 mb",
      pdf: "/pdf/XYWAV-HCP-Efficacy-Leave-Behind.pdf",
    },
    {
      title: "XYWAV Dosing and Titration Guide",
      description:
        "A comprehensive guide to starting XYWAV treatment in new patients. A print version is also available from your Jazz Representative. ",
      size: "1.4 mb",
      pdf: "/pdf/XYWAV-Dosing-and-Titration-Guide-Digital-PDF-1.pdf",
    },
    {
      title: "Idiopathic Hypersomnia Severity Scale (IHSS) Questionnaire",
      description:
        "A validated clinical tool to help track patients’ IH severity.",
      size: "574 kb",
      pdf: "/pdf/IHSS_Downloadable_PDF.pdf",
    },
    {
      title: "XYWAV INTRO AND PATIENT SPOTLIGHT LEAVE BEHIND",
      description: (
        <>
          A brief guide on clinical study results for XYWAV, the only
          FDA&#8209;approved treatment for IH.<sup>1,2</sup> A&nbsp;print
          version is also available from your Jazz Representative.
        </>
      ),
      size: "1.7 mb",
      pdf: "/pdf/XYWAV-nagivating-ih-symptoms.pdf",
    },
  ]

  const gradientCalloutData = {
    heading: <>Additional Resources for Your&nbsp;Patients</>,
    text: (
      <>
        Downloadable information for patients including an{" "}
        <AnchorIH
          isExternal
          newTab
          link="https://www.xywav.com/pdf/XYWAV-Doctor-Visit-Checklist.pdf"
          underline
          inline
        >
          Doctor Visit Checklist
        </AnchorIH>
        , the{" "}
        <AnchorIH
          isExternal
          newTab
          link="https://www.xywav.com/pdf/XYWAV_IH_Brochure.pdf"
          underline
          inline
        >
          XYWAV Idiopathic Hypersomnia Brochure
        </AnchorIH>
        , and the{" "}
        <AnchorIH
          isExternal
          newTab
          link="https://www.xywav.com/pdf/HH1157862-IHXY-ESS-Patient-Web-Download-PDF_L06.pdf"
          underline
          inline
        >
          Epworth Sleepiness Scale (ESS)
        </AnchorIH>
      </>
    ),
  }

  const keyOpinionLeaderTestimonials = [
    {
      title: "Determining a dosing regimen",
      id: "cr40o9txlx",
      thumb: data.videoDosing,
      alt: "Watch the video to learn about XYWAV's dosing regimen.",
    },
    {
      title: "XYWAV key efficacy endpoints in Study 2",
      id: "zf7eut827o",
      thumb: data.videoEfficacy,
      alt: "Watch the video about the key efficacy endpoints in a study on XYWAV.",
    },
    {
      title: "Discussing XYWAV with patients",
      id: "pimon4k0ic",
      thumb: data.videoXywav,
      alt: "Watch the video to learn more about symptoms of idiopathic hypersomnia.",
    },
    {
      title: "XYWAV key efficacy subgroup analyses",
      id: "4s38casxoq",
      thumb: data.videoSubgroup,
      alt: "Watch the video to learn about the efficacy of XYWAV within a subgroup analyses.",
    },
  ]

  const patientTestimonials = [
    {
      title: <>What to know before prescribing&nbsp;XYWAV</>,
      id: "l4soktfua5",
      thumb: data.videoPatientConsidering,
      alt: "Watch the video to hear Dr. Gerard Meskill and his patient, Diana, discuss XYWAV.",
    },
    {
      title: "Introducing XYWAV",
      id: "scztmerwg8",
      thumb: data.videoPatientIntroducing,
      alt: "Watch the video to receive an introduction to XYWAV.",
    },
    {
      title: <>Jennie’s XYWAV treatment initiation&nbsp;and&nbsp;monitoring</>,
      id: "tescaxb02m",
      thumb: data.videoPatientMonitoring,
      alt: "Watch the video to hear patient Jennie share her treatment journey with XYWAV",
    },
    {
      title: "Jennie’s treatment with XYWAV",
      id: "gq5vxq552i",
      thumb: data.videoPatientTreatment,
      alt: "Watch the video to hear patient Jennie's treatment with XYWAV.",
    },
    {
      title: "Jennie’s journey to diagnosis",
      id: "h2svtj1rwj",
      thumb: data.videoPatientDiagnosis,
      alt: "Watch the video to hear patient Jennie's journey to diagnosis.",
    },
  ]

  const crosslinksCtas = {
    heading: <>Additional support for your adult patients with IH</>,
    desktopWidth: tw`lg:(w-[345px] first:mb-4) xl:first:mb-0`,
    links: [
      {
        text: (
          <>
            patient financial assistance <br /> & support
          </>
        ),
        url: "/jazzcares-for-xywav/#financial-assistance",
      },
      {
        text: (
          <>
            patient support with <br /> MYWAV.COM
          </>
        ),
        url: "/jazzcares-for-xywav/#mywav",
      },
    ],
  }

  const referenceData = [
    <>
      XYWAV<sup>®</sup> (calcium, magnesium, potassium, and sodium oxybates).
      Prescribing Information. Palo Alto, CA: Jazz Pharmaceuticals, Inc.
    </>,
    <>
      FDA grants first of its kind indication for chronic sleep disorder
      treatment. News release. U.S. Food and Drug Administration; August 12,
      2021. Accessed March 18, 2024.
      https://www.fda.gov/news-events/press-announcements/fda-grants-first-its-kind-indication-chronic-sleep-disorder-treatment
    </>,
  ]

  const heroLinks = [
    {
      text: <>Download&nbsp;Resources</>,
      url: "/idiopathic-hypersomnia/resources/#download-resources",
    },
    {
      text: "Patient Resources",
      url: "/idiopathic-hypersomnia/resources/#patient-resources",
    },
    {
      text: "Video Library",
      url: "/idiopathic-hypersomnia/resources/#video-library",
    },
  ]

  const heroImg = withArtDirection(getImage(data.heroDesktop), [
    {
      media: "(max-width: 1279px)",
      image: getImage(data.heroMobile),
    },
  ])

  return (
    <Layout
      location={location}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200329 Rev0824
        </>
      }
    >
      <Seo
        title="Idiopathic Hypersomnia Patient Resources | XYWAV HCP"
        description="Find resources like the Idiopathic Hypersomnia Severity Scale to help assess your patients diagnosed with Idiopathic Hypersomnia (IH). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <HeroIH
        eyebrowText="Support & Resources"
        imgSrc={heroImg}
        altText="Smiling doctor shaking patient's hand"
        mobileHeight="37"
      >
        <h1
          css={[
            tw`text-white mt-2.5 mb-5 font-semibold text-[24px] leading-[1.2] max-w-[90%] 3sm:max-w-[70%] md:my-4 xl:(max-w-[650px] text-[35px] leading-none)`,
          ]}
        >
          Tools to help you support your adult patients with Idiopathic
          Hypersomnia&nbsp;(IH)
        </h1>
        <p tw="lg:(max-w-[700px])">
          A downloadable XYWAV Dosing and Titration Guide, helpful IH patient
          education, diagnostic tools, and informative videos are all right
          here.
        </p>

        <HeroLinks links={heroLinks} />
      </HeroIH>

      <Section type="primary" sectionId="download-resources">
        <Container>
          <h2 css={[Heading2, tw`mb-[25px] lg:(mb-[30px])`]}>
            Resources for you and your&nbsp;staff
          </h2>
          <ResourceCardIH ih cardData={resourceCardData} />
        </Container>
      </Section>
      <Section sectionId="patient-resources" type="none">
        <Container>
          <div
            css={[
              tw`text-[18px] mb-[50px] pl-[60px] pr-[40px] py-[60px] rounded-br-[40px] gradient-ih-resources-callout
              md:(pl-[60px] pr-[25px])
              lg:(mb-[60px] py-[50px] rounded-br-[60px])`,
            ]}
          >
            <div
              css={[
                tw`relative before:(gradient40-reverse rounded absolute top-[-20px] -left-8 h-[calc(100% + 40px)] w-[5px] rotate-180)`,
                tw`md:(before:(-top-5 h-[140%]))`,
                tw`lg:(text-4xl before:(left-[-27px]))`,
              ]}
            >
              <h3 tw="font-semibold text-[24px] mb-2.5 ">
                {gradientCalloutData.heading}
              </h3>
              <div css={[tw`lg:(text-[18px])`]}>{gradientCalloutData.text}</div>
            </div>
          </div>
        </Container>
      </Section>
      <Section type="primary" bgColour="gradient42" sectionId="video-library">
        <Container>
          <h2 css={[Heading2, tw`mb-[0px] md:(mb-0 text-[24px] mt-3)`]}>
            Video Resources
          </h2>
          <div css={[tw`mb-12 md:(mb-0 pt-6 pb-8)`]} id="KOL-videos">
            <VideoSelector
              title={"Key Opinion Leader Testimonials"}
              description="meet the experts"
              videos={keyOpinionLeaderTestimonials}
              selectedVideo={leaderSelectedVideo}
            />
          </div>
          <div tw="md:py-8" id="patient-videos">
            <VideoSelector
              title={"Patient Testimonials"}
              description="Hear from patients with IH"
              videos={patientTestimonials}
              selectedVideo={patientSelectedVideo}
            />
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <Crosslinks ctas={crosslinksCtas} />
        </Container>
      </Section>
      <References referenceData={referenceData} paddingPrimary />
    </Layout>
  )
}

export default ResourcesIH

ResourcesIH.propTypes = {
  location: PropTypes.object.isRequired,
}

export const query = graphql`
  query IHResourcesQuery {
    heroDesktop: file(
      relativePath: { eq: "resources-ih-page/hero-resources-ih-desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heroMobile: file(
      relativePath: { eq: "resources-ih-page/ih-resources-mobile-update.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoXywav: file(
      relativePath: {
        eq: "video-thumbs/idiopathic-hypersomnia-symptoms-video-thumbnail.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoDosing: file(
      relativePath: { eq: "video-thumbs/dosing-regimen-video-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoEfficacy: file(
      relativePath: {
        eq: "video-thumbs/primary-efficacy-endpoint-video-thumbnail.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoSubgroup: file(
      relativePath: { eq: "video-thumbs/xywav-efficacy-analyses.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoPatientDiagnosis: file(
      relativePath: {
        eq: "video-thumbs/patient-jennie-ih-diagnosis-video-thumbnail.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoPatientMonitoring: file(
      relativePath: { eq: "video-thumbs/patient-jennie-video-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoPatientTreatment: file(
      relativePath: {
        eq: "video-thumbs/patient-jennie-xywav-treatment-video-thumbnail.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoPatientConsidering: file(
      relativePath: { eq: "video-thumbs/patient-diana-ih-video-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoPatientIntroducing: file(
      relativePath: {
        eq: "video-thumbs/xywav-introduction-video-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
