import React, { Fragment } from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"
import AnchorIH from "@components/anchorIH"

const HeroLinks = ({ links }) => {
  return (
    <div
      css={[
        tw`mt-2.5 mb-5 w-full md:w-[140%] whitespace-normal md:(mb-0 mt-4) lg:(whitespace-nowrap max-w-none)`,
      ]}
    >
      <p tw="block text-center mb-2 md:(text-left inline-block mb-4) lg:(mb-0)">
        <strong>Go&nbsp;to:</strong>
      </p>
      {links.map((link, i) => {
        const count = links.length - 1
        const isLast = i === count
        return (
          <Fragment key={`HeroLinks-${i}`}>
            <div
              css={[
                tw`block mb-3 px-[20px] text-center leading-[0.8] md:(text-left px-[20px] inline-block) lg:(mb-0 )`,
                isLast && tw`md:(mt-4 inline-block) xl:( ml-0 mt-0)`,
                count === 0 && tw`md:(inline-block px-[20px]) lg:(ml-0 mt-0)`,
              ]}
              key={i}
              className="hero-link"
            >
              <AnchorIH link={link.url} bold underline textWhite>
                {link.text}
              </AnchorIH>
            </div>
            {!isLast && <span tw="font-bold hidden md:inline-block">|</span>}
          </Fragment>
        )
      })}
    </div>
  )
}

export default HeroLinks

HeroLinks.propTypes = {
  links: PropTypes.array.isRequired,
}
